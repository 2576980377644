define("ember-quickstart/themes/plain-html", ["exports", "ember-models-table/themes/plain-html"], function (_exports, _plainHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @class PlainHtmlTheme
   * @namespace Themes
   * @extends Themes.Default
   */
  class PlainHtmlTheme extends _plainHtml.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "columnVisibleIcon", 'far fa-check-square');

      _defineProperty(this, "columnHiddenIcon", 'far fa-square');

      _defineProperty(this, "sortAscIcon", 'fas fa-sort-alpha-up');

      _defineProperty(this, "sortDescIcon", 'fas fa-sort-alpha-down-alt');

      _defineProperty(this, "deselectAllRowsIcon", 'far fa-square');

      _defineProperty(this, "deselectRowIcon", 'far fa-square');

      _defineProperty(this, "selectAllRowsIcon", 'far fa-check-square');

      _defineProperty(this, "selectRowIcon", 'far fa-check-square');

      _defineProperty(this, "selectSomeRowsIcon", 'far fa-minus-square');
    }

  }

  _exports.default = PlainHtmlTheme;
});