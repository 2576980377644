define("ember-quickstart/transforms/string-string", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StringStringTransform extends _transform.default {
    deserialize(serialized) {
      return Ember.isNone(serialized) ? null : String(serialized);
    }

    serialize(deserialized) {
      return Ember.isNone(deserialized) ? null : String(deserialized);
    }

  }

  _exports.default = StringStringTransform;
});