define("ember-quickstart/models/maintenance", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MaintenanceModel = (_dec = (0, _model.attr)('date-string', {
    allowNull: true
  }), _dec2 = (0, _model.attr)('date-string', {
    allowNull: true
  }), _dec3 = (0, _model.attr)('date-string', {
    allowNull: true
  }), _dec4 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec5 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec6 = (0, _model.attr)('string-string'), _dec7 = (0, _model.attr)('boolean-string'), _dec8 = (0, _model.belongsTo)('pylon'), _dec9 = (0, _model.belongsTo)('maintenance-type'), _dec10 = (0, _model.hasMany)('entity', {
    inverse: 'inspected_maintenances'
  }), _dec11 = (0, _model.hasMany)('agreement'), _dec12 = (0, _model.hasMany)('attachment'), (_class = (_temp = class MaintenanceModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "inspected_on", _descriptor, this);

      _initializerDefineProperty(this, "last_inspected_on", _descriptor2, this);

      _initializerDefineProperty(this, "next_inspected_on", _descriptor3, this);

      _initializerDefineProperty(this, "consequence_to_life", _descriptor4, this);

      _initializerDefineProperty(this, "frequency", _descriptor5, this);

      _initializerDefineProperty(this, "description", _descriptor6, this);

      _initializerDefineProperty(this, "is_published", _descriptor7, this);

      _initializerDefineProperty(this, "pylon", _descriptor8, this);

      _initializerDefineProperty(this, "maintenance_type", _descriptor9, this);

      _initializerDefineProperty(this, "inspectors", _descriptor10, this);

      _initializerDefineProperty(this, "agreements", _descriptor11, this);

      _initializerDefineProperty(this, "attachments", _descriptor12, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inspected_on", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "last_inspected_on", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "next_inspected_on", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "consequence_to_life", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "frequency", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "is_published", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pylon", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "maintenance_type", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "inspectors", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "agreements", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MaintenanceModel;
});