define("ember-quickstart/components/content/pylon-data-viewer2/table-box", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ModelsTable
    @data={{@result}}
    @columns={{@columns}}
    @columnsAreUpdateable={{true}}
    @expandedRowComponent={{component "content/pylon-data-viewer/pylon-result" 
                              modelName=@modelName}}
  />
  */
  {
    id: "Gu3FcDax",
    block: "{\"symbols\":[\"@result\",\"@columns\",\"@modelName\"],\"statements\":[[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@columnsAreUpdateable\",\"@expandedRowComponent\"],[[27,[24,1],[]],[27,[24,2],[]],true,[31,116,9,[27,[26,0,\"CallHead\"],[]],[\"content/pylon-data-viewer/pylon-result\"],[[\"modelName\"],[[27,[24,3],[]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    meta: {
      moduleName: "ember-quickstart/components/content/pylon-data-viewer2/table-box.hbs"
    }
  });

  class ContentPylonDataViewer2TableBoxComponent extends _component.default {}

  _exports.default = ContentPylonDataViewer2TableBoxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContentPylonDataViewer2TableBoxComponent);
});