define("ember-quickstart/components/util/create-button-lv-one", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button class="btn btn-block rounded-0 border-top text-primary" {{action this.pylonDataEntry.toggleModal2}}>Create "{{@model}}"</button>
  */
  {
    id: "iBT/4X3U",
    block: "{\"symbols\":[\"@model\"],\"statements\":[[9,\"button\",false],[23,\"class\",\"btn btn-block rounded-0 border-top text-primary\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],[27,[24,0],[\"pylonDataEntry\",\"toggleModal2\"]]],null],[10],[1,1,0,0,\"Create \\\"\"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\\"\"],[11]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    meta: {
      moduleName: "ember-quickstart/components/util/create-button-lv-one.hbs"
    }
  });

  let UtilCreateButtonLvOneComponent = (_dec = Ember.inject.controller, (_class = (_temp = class UtilCreateButtonLvOneComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "pylonDataEntry", _descriptor, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pylonDataEntry", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UtilCreateButtonLvOneComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UtilCreateButtonLvOneComponent);
});