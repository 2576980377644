define("ember-quickstart/instance-initializers/register-fullscreen-control-component", ["exports", "ember-leaflet-fullscreen-control/instance-initializers/register-component"], function (_exports, _registerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _registerComponent.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _registerComponent.initialize;
    }
  });
});