define("ember-quickstart/controllers/pylon-data-entry", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PylonDataEntryController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = (0, _emberConcurrency.task)(function* (modelName) {
    yield (0, _emberConcurrency.timeout)(100);
    this.optionsPage++;

    if (this.isSearchMode === false) {
      //console.log('Missed');
      if (this.hasNext === true) {
        this.store.query(modelName, {
          page: {
            "offset": this.defaultPageSize * this.optionsPage,
            "limit": this.defaultPageSize
          }
        }).then(model => {
          if (Ember.isEmpty(model.links.next)) {
            this.hasNext = false;
          }

          ;
          this.options.pushObjects(model.toArray());
        });
      }
    } else if (this.isSearchMode === true) {
      //console.log('Entered');
      switch (modelName) {
        //Can't find a way to replace searchField(e.g. alias) with argument, it will directly using the text instead of treating it as argument
        case 'circuit':
          if (this.hasNext === true) {
            this.store.query(modelName, {
              filter: {
                "alias": {
                  like: '%' + this.searchText + '%'
                }
              },
              page: {
                "offset": this.defaultPageSize * this.optionsPage,
                "limit": this.defaultPageSize
              }
            }).then(model => {
              if (Ember.isEmpty(model.links.next)) {
                this.hasNext = false;
              } else {
                this.hasNext = true;
              }

              ;
              this.options.pushObjects(model.toArray());
            });
          }

          break;

        default:
          if (this.hasNext === true) {
            this.store.query(modelName, {
              filter: {
                "name": {
                  like: '%' + this.searchText + '%'
                }
              },
              page: {
                "offset": this.defaultPageSize * this.optionsPage,
                "limit": this.defaultPageSize
              }
            }).then(model => {
              if (Ember.isEmpty(model.links.next)) {
                this.hasNext = false;
              } else {
                this.hasNext = true;
              }

              ;
              this.options.pushObjects(model.toArray());
            });
          }

      }
    }
  }).drop(), _dec15 = (0, _emberConcurrency.task)(function* (modelName, modelNameLvOne) {
    // yield timeout(300);
    //console.log(this.optionsPage)
    this.optionsPage = 0;
    this.hasNext = true;
    this.isSearchMode = false;
    this.focusedModel = modelName;
    this.focusedModelLvOne = modelNameLvOne;
    this.options = []; //console.log(this.focusedModel);
    //console.log(this.focusedModelLvOne);

    if (Ember.isEmpty(modelNameLvOne)) {
      if (this.loadedModel.includes(modelName)) {
        for (let i = 1; i <= this.defaultPageSize; i++) {
          if (!Ember.isEmpty(this.store.peekRecord(modelName, i))) this.options.push(this.store.peekRecord(modelName, i));
        }
      } else {
        yield this.store.query(modelName, {}).then(model => {
          this.options = model.toArray();
        });
        this.loadedModel.push(modelName);
      }
    } else {
      if (this.loadedModel.includes(modelNameLvOne)) {
        for (let i = 1; i <= this.defaultPageSize; i++) {
          if (!Ember.isEmpty(this.store.peekRecord(modelNameLvOne, i))) this.options.push(this.store.peekRecord(modelNameLvOne, i));
        }
      } else {
        yield this.store.query(modelNameLvOne, {}).then(model => {
          this.options = model.toArray();
        });
        this.loadedModel.push(modelNameLvOne);
      }
    }
  }).restartable(), _dec16 = (0, _emberConcurrency.task)(function* (modelName, text) {
    yield (0, _emberConcurrency.timeout)(300);
    this.optionsPage = 0;
    this.hasNext = false;
    this.isSearchMode = true;
    this.searchText = text; //console.log(this.isSearchMode);

    switch (modelName) {
      //Can't find a way to replace searchField(e.g. alias) with argument, it will directly using the text instead of treating it as argument
      case 'circuit':
        this.store.query(modelName, {
          filter: {
            "alias": {
              like: '%' + this.searchText + '%'
            }
          }
        }).then(model => {
          this.options = model.toArray();

          if (Ember.isEmpty(model.links.next)) {
            this.hasNext = false;
          } else {
            this.hasNext = true;
          }

          ;
        });
        break;

      default:
        this.store.query(modelName, {
          filter: {
            "name": {
              like: '%' + this.searchText + '%'
            }
          }
        }).then(model => {
          this.options = model.toArray();

          if (Ember.isEmpty(model.links.next)) {
            this.hasNext = false;
          } else {
            this.hasNext = true;
          }

          ;
        });
    }
  }).restartable(), _dec17 = Ember._action, _dec18 = Ember._action, (_class = (_temp = class PylonDataEntryController extends Ember.Controller {
    //Required for distinguishment because searchResult made use of options(Public API) instead of default searchResult
    constructor() {
      super(...arguments); // this.store.query('pylon', {
      //     include : 'circuit, foundation-types, locations, type, responsible-party'
      //               +',face-a-body-extension-type, face-b-body-extension-type, face-c-body-extension-type, face-d-body-extension-type'
      //               +',face-a-leg-extension-type, face-b-leg-extension-type, face-c-leg-extension-type, face-d-leg-extension-type'
      //               +',face-ab-common-portion-type, face-cd-common-portion-type, face-ab-crossarm-type, face-cd-crossarm-type'
      //               +',attachments',
      //     page: { 
      //         "offset": this.optionsPage,
      //         "limit" : this.defaultPageSize
      //     }
      // });

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "defaultPageSize", 20);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      _initializerDefineProperty(this, "selectedPylon", _descriptor3, this);

      _initializerDefineProperty(this, "confirmedPylon", _descriptor4, this);

      _initializerDefineProperty(this, "optionsPage", _descriptor5, this);

      _initializerDefineProperty(this, "hasNext", _descriptor6, this);

      _initializerDefineProperty(this, "options", _descriptor7, this);

      _initializerDefineProperty(this, "searchText", _descriptor8, this);

      _initializerDefineProperty(this, "isSearchMode", _descriptor9, this);

      _initializerDefineProperty(this, "focusedModel", _descriptor10, this);

      _initializerDefineProperty(this, "focusedModelLvOne", _descriptor11, this);

      _defineProperty(this, "loadedModel", []);

      _initializerDefineProperty(this, "loadMoreOptions", _descriptor12, this);

      _initializerDefineProperty(this, "loadDefaultOptions", _descriptor13, this);

      _initializerDefineProperty(this, "searchOptions", _descriptor14, this);

      _defineProperty(this, "isShowingModal", false);

      _defineProperty(this, "isShowingModal2", false);
    }

    clickedCreate() {
      this.status = "createMode"; //Clean up change made in updateMode

      this.selectedPylon = '';
      this.confirmedPylon = ''; //console.log(this.status);
      //console.log(this.confirmedPylon);
    }

    clickedUpdate() {
      if (this.status === "updateMode") {
        this.status = "updateMode2";
      } else {
        this.status = "updateMode";
      } //Confirm selection and Pass it to Form components


      this.confirmedPylon = this.selectedPylon; //console.log(this.status);
    }

    toggleModal() {
      this.toggleProperty('isShowingModal');
    } //Control for modalLvOne


    toggleModal2() {
      this.toggleProperty('isShowingModal2');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'createMode';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedPylon", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "confirmedPylon", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "optionsPage", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasNext", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "searchText", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isSearchMode", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "focusedModel", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "focusedModelLvOne", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clickedCreate", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "clickedCreate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickedUpdate", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "clickedUpdate"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "loadMoreOptions", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "loadDefaultOptions", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "searchOptions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleModal", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "toggleModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleModal2", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleModal2"), _class.prototype)), _class));
  _exports.default = PylonDataEntryController;
});