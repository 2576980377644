define("ember-quickstart/models/pylon", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PylonModel = (_dec = (0, _model.attr)('string-string'), _dec2 = (0, _model.attr)('string-string', {
    allowNull: true
  }), _dec3 = (0, _model.attr)('boolean-string'), _dec4 = (0, _model.attr)('boolean-string'), _dec5 = (0, _model.attr)('string-string', {
    allowNull: true
  }), _dec6 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec7 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec8 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec9 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec10 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec11 = (0, _model.attr)('numeric-string', {
    allowNull: true
  }), _dec12 = (0, _model.attr)('boolean-string'), _dec13 = (0, _model.belongsTo)('pylon-type', {
    allowNull: true
  }), _dec14 = (0, _model.belongsTo)('responsible-party', {
    allowNull: true
  }), _dec15 = (0, _model.belongsTo)('body-extension-type', {
    inverse: 'face_a_pylons',
    allowNull: true
  }), _dec16 = (0, _model.belongsTo)('body-extension-type', {
    inverse: 'face_b_pylons',
    allowNull: true
  }), _dec17 = (0, _model.belongsTo)('body-extension-type', {
    inverse: 'face_c_pylons',
    allowNull: true
  }), _dec18 = (0, _model.belongsTo)('body-extension-type', {
    inverse: 'face_d_pylons',
    allowNull: true
  }), _dec19 = (0, _model.belongsTo)('leg-extension-type', {
    inverse: 'face_a_pylons',
    allowNull: true
  }), _dec20 = (0, _model.belongsTo)('leg-extension-type', {
    inverse: 'face_b_pylons',
    allowNull: true
  }), _dec21 = (0, _model.belongsTo)('leg-extension-type', {
    inverse: 'face_c_pylons',
    allowNull: true
  }), _dec22 = (0, _model.belongsTo)('leg-extension-type', {
    inverse: 'face_d_pylons',
    allowNull: true
  }), _dec23 = (0, _model.belongsTo)('common-portion-type', {
    inverse: 'face_ab_pylons',
    allowNull: true
  }), _dec24 = (0, _model.belongsTo)('common-portion-type', {
    inverse: 'face_cd_pylons',
    allowNull: true
  }), _dec25 = (0, _model.belongsTo)('crossarm-type', {
    inverse: 'face_ab_pylons',
    allowNull: true
  }), _dec26 = (0, _model.belongsTo)('crossarm-type', {
    inverse: 'face_cd_pylons',
    allowNull: true
  }), _dec27 = (0, _model.belongsTo)('circuit', {
    allowNull: true
  }), _dec28 = (0, _model.hasMany)('access-path'), _dec29 = (0, _model.hasMany)('tower'), _dec30 = (0, _model.hasMany)('conductor'), _dec31 = (0, _model.hasMany)('foundation'), _dec32 = (0, _model.hasMany)('slope'), _dec33 = (0, _model.hasMany)('ground-investigation'), _dec34 = (0, _model.hasMany)('maintenance'), _dec35 = (0, _model.hasMany)('foundation-type'), _dec36 = (0, _model.hasMany)('location'), _dec37 = (0, _model.hasMany)('attachment'), (_class = (_temp = class PylonModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "description", _descriptor2, this);

      _initializerDefineProperty(this, "is_gantry", _descriptor3, this);

      _initializerDefineProperty(this, "is_substation", _descriptor4, this);

      _initializerDefineProperty(this, "construction_number", _descriptor5, this);

      _initializerDefineProperty(this, "height", _descriptor6, this);

      _initializerDefineProperty(this, "ground_elevation", _descriptor7, this);

      _initializerDefineProperty(this, "centre_latitude", _descriptor8, this);

      _initializerDefineProperty(this, "centre_longitude", _descriptor9, this);

      _initializerDefineProperty(this, "centre_northing", _descriptor10, this);

      _initializerDefineProperty(this, "centre_easting", _descriptor11, this);

      _initializerDefineProperty(this, "is_published", _descriptor12, this);

      _initializerDefineProperty(this, "pylon_type", _descriptor13, this);

      _initializerDefineProperty(this, "responsible_party", _descriptor14, this);

      _initializerDefineProperty(this, "face_a_body_extension_type", _descriptor15, this);

      _initializerDefineProperty(this, "face_b_body_extension_type", _descriptor16, this);

      _initializerDefineProperty(this, "face_c_body_extension_type", _descriptor17, this);

      _initializerDefineProperty(this, "face_d_body_extension_type", _descriptor18, this);

      _initializerDefineProperty(this, "face_a_leg_extension_type", _descriptor19, this);

      _initializerDefineProperty(this, "face_b_leg_extension_type", _descriptor20, this);

      _initializerDefineProperty(this, "face_c_leg_extension_type", _descriptor21, this);

      _initializerDefineProperty(this, "face_d_leg_extension_type", _descriptor22, this);

      _initializerDefineProperty(this, "face_ab_common_portion_type", _descriptor23, this);

      _initializerDefineProperty(this, "face_cd_common_portion_type", _descriptor24, this);

      _initializerDefineProperty(this, "face_ab_crossarm_type", _descriptor25, this);

      _initializerDefineProperty(this, "face_cd_crossarm_type", _descriptor26, this);

      _initializerDefineProperty(this, "circuit", _descriptor27, this);

      _initializerDefineProperty(this, "access_paths", _descriptor28, this);

      _initializerDefineProperty(this, "towers", _descriptor29, this);

      _initializerDefineProperty(this, "conductors", _descriptor30, this);

      _initializerDefineProperty(this, "foundations", _descriptor31, this);

      _initializerDefineProperty(this, "slopes", _descriptor32, this);

      _initializerDefineProperty(this, "ground_investigations", _descriptor33, this);

      _initializerDefineProperty(this, "maintenances", _descriptor34, this);

      _initializerDefineProperty(this, "foundation_types", _descriptor35, this);

      _initializerDefineProperty(this, "locations", _descriptor36, this);

      _initializerDefineProperty(this, "attachments", _descriptor37, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "is_gantry", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "is_substation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "construction_number", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ground_elevation", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "centre_latitude", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "centre_longitude", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "centre_northing", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "centre_easting", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "is_published", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "pylon_type", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "responsible_party", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "face_a_body_extension_type", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "face_b_body_extension_type", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "face_c_body_extension_type", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "face_d_body_extension_type", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "face_a_leg_extension_type", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "face_b_leg_extension_type", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "face_c_leg_extension_type", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "face_d_leg_extension_type", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "face_ab_common_portion_type", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "face_cd_common_portion_type", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "face_ab_crossarm_type", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "face_cd_crossarm_type", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "circuit", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "access_paths", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "towers", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "conductors", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "foundations", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "slopes", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "ground_investigations", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "maintenances", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "foundation_types", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "locations", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PylonModel;
});