define("ember-quickstart/routes/pylon-data-entry/access-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataEntryAccessPathRoute extends Ember.Route {}

  _exports.default = PylonDataEntryAccessPathRoute;
});