define("ember-quickstart/templates/pylon-data-viewer/slope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/vX9R46c",
    "block": "{\"symbols\":[],\"statements\":[[7,\"content/pylon-data-viewer/slope-result\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/pylon-data-viewer/slope.hbs"
    }
  });

  _exports.default = _default;
});