define("ember-quickstart/transforms/boolean-string", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BooleanStringTransform extends _transform.default {
    deserialize(serialized) {
      if (Ember.isNone(serialized) && options.allowNull === true) {
        return null;
      }

      let type = typeof serialized;

      if (type === 'boolean') {
        return serialized;
      } else if (type === 'string') {
        return /^(true|t|1)$/i.test(serialized);
      } else if (type === 'number') {
        return serialized === 1;
      } else {
        return false;
      }
    }

    serialize(deserialized) {
      if (Ember.isNone(deserialized) && options.allowNull === true) {
        return null;
      }

      return Boolean(deserialized).toString();
    }

  }

  _exports.default = BooleanStringTransform;
});