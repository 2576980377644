define("ember-quickstart/components/models-table-server-paginated", ["exports", "ember-models-table/components/models-table-server-paginated"], function (_exports, _modelsTableServerPaginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ModelsTableServerPaginatedComponent = (_dec = Ember.computed('filteredContent.meta', 'metaPagesCountProperty', 'pageSize'), (_class = class ModelsTableServerPaginatedComponent extends _modelsTableServerPaginated.default {
    /**
     * This function actually loads the data from the server.
     * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
     *
     * @return Promise
     * @method _loadData
     * @private
     */
    _loadData() {
      const {
        data,
        currentPageNumber,
        pageSize,
        processedColumns: columns,
        sortProperties,
        filterString
      } = this;

      if (!data.query) {
        (true && Ember.warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data', false, {
          id: '#emt-query-usage'
        }));
        return;
      }

      let query = Object.assign({}, data.query);
      let store = data.store;
      let modelName = data.type.modelName; // Add pagination information

      query[this.filterQueryParameters.page] = (currentPageNumber - 1) * pageSize;
      query[this.filterQueryParameters.pageSize] = pageSize; // Add sorting information

      if (sortProperties && sortProperties.length) {
        if (this.multipleColumnsSorting) {
          query = this.multipleColumnsSortingWrapper(query, sortProperties);
        } else {
          if (sortProperties[0]) {
            let [sortBy, sortDirection] = sortProperties[0].split(':');
            query = this.singleColumnSortingWrapper(query, sortBy, sortDirection.toUpperCase());
          }
        }
      } else {
        delete query[[this.filterQueryParameters.sort]];
        delete query[[this.filterQueryParameters.sortDirection]];
      } // Add global filter


      let globalFilter = this.filterQueryParameters.globalFilter;

      if (filterString) {
        query[globalFilter] = filterString;
      } else {
        delete query[globalFilter];
      } // Add per-column filter


      if (this.useFilteringByColumns) {
        columns.forEach(column => {
          let filter = column.filterString; // let filter = '%'+ column.filterString +'%';

          let filterTitle = this.getCustomFilterTitle(column);

          if (filterTitle.includes('][like]')) {
            if (Ember.isEmpty(filter)) this.setQueryFilter(query, column, filterTitle, filter);else this.setQueryFilter(query, column, filterTitle, '%' + filter + '%');
          } else this.setQueryFilter(query, column, filterTitle, filter);
        });
      }

      Ember.setProperties(this, {
        isLoading: true,
        isError: false
      });
      return this.doQuery(store, modelName, query).then(() => Ember.setProperties(this, {
        isLoading: false,
        isError: false
      })).catch(() => Ember.setProperties(this, {
        isLoading: false,
        isError: true
      }));
    }
    /**
     * Wrapper for sorting query when single column sorting is used
     *
     * @param {object} query parameters
     * @param {string} sortBy
     * @param {string} sortDirection
     * @return object query parameters
     * @method singleColumnSortingWrapper
     */


    singleColumnSortingWrapper(query, sortBy, sortDirection) {
      if (sortDirection === 'ASC') query[this.filterQueryParameters.sort] = sortBy;else if (sortDirection === 'DESC') query[this.filterQueryParameters.sort] = '-' + sortBy; // query[this.filterQueryParameters.sortDirection] = sortDirection;

      return query;
    }
    /**
     * The pages count is get from the meta information.
     * Set metaPagesCountProperty to change from which meta property this is loaded.
     *
     * @property pagesCount
     * @type number
     * @protected
     */


    get pagesCount() {
      const meta = this.filteredContent && this.filteredContent.meta ? this.filteredContent.meta : {};
      return Math.ceil(Ember.get(meta, this.metaItemsCountProperty) / this.pageSize) || 1;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "pagesCount", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pagesCount"), _class.prototype)), _class));
  _exports.default = ModelsTableServerPaginatedComponent;
  ;
});