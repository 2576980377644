define("ember-quickstart/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSerializer extends _jsonApi.default {
    normalizeQueryResponse(a, b, payload) {
      const newPayload = super.normalizeQueryResponse(...arguments); // console.log(payload.meta.items.total)

      newPayload.meta = newPayload.meta || {};
      newPayload.meta.itemsCount = payload.meta.total_items; // newPayload.meta.itemsCount = 50;
      // newPayload.meta.pagesCount = payload.meta.items.total/10+1;
      // console.log(newPayload)

      return newPayload;
    }

  }

  _exports.default = ApplicationSerializer;
});