define("ember-quickstart/components/content/pylon-data-verifier/maintenance-inspection", ["exports", "@glimmer/component", "ember-concurrency", "sweetalert2"], function (_exports, _component, _emberConcurrency, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.loaded}}
      <ModelsTableServerPaginated
              @data={{this.model}}
              @columns={{this.columns}}
              @filterQueryParameters={{this.filterQueryParameters}}
              @currentPageNumber={{this.page}}
              @pageSize={{this.pageSize}}
              @showGlobalFilter={{false}}
              @useFilteringByColumns={{false}}
              @multipleSelect={{true}}
              @selectedItems={{this.actionData}}
      />
  {{/if}}
  
  <div style="max-width: 800px; margin: auto;">
      <button class="btn btn-primary" style="width:100%" {{action this.submit}} >Submit</button>
  </div>
  
  */
  {
    id: "PNEiw+W/",
    block: "{\"symbols\":[],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"loaded\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"models-table-server-paginated\",[],[[\"@data\",\"@columns\",\"@filterQueryParameters\",\"@currentPageNumber\",\"@pageSize\",\"@showGlobalFilter\",\"@useFilteringByColumns\",\"@multipleSelect\",\"@selectedItems\"],[[27,[24,0],[\"model\"]],[27,[24,0],[\"columns\"]],[27,[24,0],[\"filterQueryParameters\"]],[27,[24,0],[\"page\"]],[27,[24,0],[\"pageSize\"]],false,false,true,[27,[24,0],[\"actionData\"]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"style\",\"max-width: 800px; margin: auto;\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"button\",false],[23,\"class\",\"btn btn-primary\",null],[23,\"style\",\"width:100%\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],[27,[24,0],[\"submit\"]]],null],[10],[1,1,0,0,\"Submit\"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\"]}",
    meta: {
      moduleName: "ember-quickstart/components/content/pylon-data-verifier/maintenance-inspection.hbs"
    }
  });

  let ContentPylonDataVerifierMaintenanceInspectionComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = (0, _emberConcurrency.task)(function* () {
    //function* ~ async
    let promises = [];
    let entryCount = this.actionData.length;
    this.actionData.forEach(record => {
      record.is_published = true;
      let promise = record.save();
      promises.push(promise);
    });
    yield Promise.all(promises.map(p => p.catch(e => e))).then(results => {
      // console.log(results.filter(result => !(result instanceof Error)))
      this.actionData = this.actionData.filter(x => !results.filter(result => !(result instanceof Error)).includes(x)); // console.log(results.filter(result => (result instanceof Error)))

      if (results.filter(result => result instanceof Error).length === 0) {
        _sweetalert.default.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Updated Successfully!',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        _sweetalert.default.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: results.filter(result => result instanceof Error).length + ' of ' + entryCount + ' record(s) Failed to Update!',
          showConfirmButton: false,
          timer: 3000
        });
      }
    }).then(() => {
      this.getTableData();
    });
  }).drop(), _dec10 = Ember._action, (_class = (_temp = class ContentPylonDataVerifierMaintenanceInspectionComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "page", _descriptor2, this);

      _initializerDefineProperty(this, "pageSize", _descriptor3, this);

      _initializerDefineProperty(this, "loaded", _descriptor4, this);

      _initializerDefineProperty(this, "model", _descriptor5, this);

      _initializerDefineProperty(this, "columns", _descriptor6, this);

      _initializerDefineProperty(this, "actionData", _descriptor7, this);

      _defineProperty(this, "theme", Ember.getOwner(this).lookup('component:models-table-server-paginated').themeInstance);

      _defineProperty(this, "filterQueryParameters", {
        pageSize: 'page[limit]',
        page: 'page[offset]',
        sort: 'sort' // sortDirection: 'sortDirection',

      });

      _initializerDefineProperty(this, "submitTask", _descriptor8, this);

      this.getTableData();
    }

    submit() {
      this.submitTask.perform();
    }

    getTableData() {
      this.store.query('maintenance', {
        // USE FIELD INSTEAD!!!
        filter: {
          or: {
            'is-published': {
              'is-null': null,
              neq: true
            }
          }
        },
        include: "pylon, agreements, maintenance-type, inspectors",
        page: {
          "offset": (this.page - 1) * this.pageSize,
          "limit": this.pageSize
        }
      }).then(model => {
        this.model = model;
        this.loaded = true;
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pageSize", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loaded", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        component: this.theme.rowSelectCheckboxComponent,
        // useFilter: false,
        mayBeHidden: false,
        componentForSortCell: this.theme.rowSelectAllCheckboxComponent
      }, {
        "propertyName": "id",
        "title": "ID"
      }, {
        "propertyName": "inspected_on",
        "title": "Inspected On",
        'component': 'util/models-table/date'
      }, {
        "propertyName": "last_inspected_on",
        "title": "Last Inspected On",
        'component': 'util/models-table/date'
      }, {
        "propertyName": "next_inspected_on",
        "title": "Next Inspected On",
        'component': 'util/models-table/date'
      }, {
        "propertyName": "description",
        "title": "Description"
      }, {
        "propertyName": "pylon.name",
        "title": "Pylon"
      }, {
        "propertyName": "agreements.firstObject.name",
        "title": "Agreement",
        'component': 'util/models-table/agreement'
      }, {
        "propertyName": "consequence_to_life",
        "title": "C-T-L"
      }, {
        "propertyName": "inspectors.firstObject.name",
        "title": "Inspector"
      }, {
        "propertyName": "frequency",
        "title": "Frequency"
      }, {
        "propertyName": "maintenance_type.name",
        "title": "Type"
      }];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "actionData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "submitTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getTableData", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "getTableData"), _class.prototype)), _class));
  _exports.default = ContentPylonDataVerifierMaintenanceInspectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContentPylonDataVerifierMaintenanceInspectionComponent);
});