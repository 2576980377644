define("ember-quickstart/helpers/get-coloured-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function getColouredIcon([circuitAlias]) {
    var icon; // iconSize: [25, 41], iconAnchor: [12, 41], popupAnchor: [1, -34],

    var blueIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var goldIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var redIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var greenIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var orangeIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var yellowIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var violetIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var greyIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });
    var blackIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png',
      iconSize: [15, 24],
      iconAnchor: [7, 24],
      popupAnchor: [1, -34]
    });

    switch (circuitAlias) {
      case 'STHK - BPA Line':
      case 'STHK - TWS Line':
      case 'STHK - 3FND Line':
        icon = yellowIcon;
        break;

      case 'STHK - BPB Line':
      case 'STHK - BPC Line':
      case 'STHK - 3FNC Line':
        icon = greenIcon;
        break;

      case 'STHK - CPH Line':
      case 'STHK - KCR Line':
      case 'STHK - 3FNB Line':
        icon = goldIcon;
        break;

      case 'STHK - CP Line':
      case 'STHK - HMB Line':
      case 'STHK - JCB Line':
        icon = redIcon;
        break;

      case 'STHK - SH Line':
      case 'STHK - DYA Line':
      case 'STHK - POB Line':
        icon = orangeIcon;
        break;

      case 'STHK - CPA Line':
      case 'STHK - CPE Line':
      case 'STHK - POC Line':
      case 'STHK - OLA Line':
        icon = violetIcon;
        break;

      case 'STHK - DYC Line':
      case 'STHK - CPD Line':
      case 'STHK - POC Line':
        icon = greyIcon;
        break;

      case 'STHK - HMC Line':
      case 'STHK - 3FNE Line':
        icon = blackIcon;
        break;

      default:
        icon = blueIcon;
    }

    return icon;
  });

  _exports.default = _default;
});