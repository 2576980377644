define("ember-quickstart/components/main-sidebar/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <!-- Brand Logo -->
  <a href="#" class="brand-link">
      <img src="/dist/assets/images/fhk.png" alt="FHK" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">Fugro (Hong Kong)</span>
  </a>
  
  */
  {
    id: "kAkCxLFp",
    block: "{\"symbols\":[],\"statements\":[[2,\" Brand Logo \"],[1,1,0,0,\"\\n\"],[9,\"a\",true],[12,\"href\",\"#\",null],[12,\"class\",\"brand-link\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"img\",true],[12,\"src\",\"/dist/assets/images/fhk.png\",null],[12,\"alt\",\"FHK\",null],[12,\"class\",\"brand-image img-circle elevation-3\",null],[12,\"style\",\"opacity: .8\",null],[10],[11],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[12,\"class\",\"brand-text font-weight-light\",null],[10],[1,1,0,0,\"Fugro (Hong Kong)\"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "ember-quickstart/components/main-sidebar/logo.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});