define("ember-quickstart/controllers/pylon-data-viewer2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PylonDataViewer2Controller = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class PylonDataViewer2Controller extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "result", _descriptor, this);

      _initializerDefineProperty(this, "columns", _descriptor2, this);

      _initializerDefineProperty(this, "modelName", _descriptor3, this);

      _defineProperty(this, "theme", Ember.getOwner(this).lookup('component:models-table').themeInstance);
    }

    setResult(result) {
      this.result = result; // console.log("Controller Result:",this.result)
    }

    setModelName(modelName) {
      this.modelName = modelName;

      if (this.modelName === 'pylon') {
        this.columns = [{
          component: this.theme.expandToggleComponent,
          mayBeHidden: false
        }, {
          "propertyName": "id",
          "title": "ID",
          "isHidden": "true"
        }, {
          "propertyName": "name",
          "title": "Tower No."
        }];
      }

      if (this.modelName === 'tower') {
        this.columns = [{
          component: this.theme.expandToggleComponent,
          mayBeHidden: false
        }, {
          "propertyName": "id",
          "title": "ID",
          "isHidden": "true"
        }, {
          "propertyName": "pylon.name",
          "title": "Tower No."
        }, {
          "propertyName": "agreements.name",
          "title": "Agreements",
          'component': 'util/models-table/agreement',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "works.name",
          "title": "Works",
          'component': 'util/models-table/work',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "designers.firstObject.name",
          "title": "Designer",
          "isHidden": "true"
        }, {
          "propertyName": "designed_on",
          "title": "Designed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "checkers.firstObject.name",
          "title": "Checker",
          "isHidden": "true"
        }, {
          "propertyName": "checked_on",
          "title": "Checked On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "constructors.firstObject.name",
          "title": "Constructor",
          "isHidden": "true"
        }, {
          "propertyName": "constructed_on",
          "title": "Constructed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "description",
          "title": "Description",
          "isHidden": "true"
        }];
      }

      if (this.modelName === 'conductor') {
        this.columns = [{
          component: this.theme.expandToggleComponent,
          mayBeHidden: false
        }, {
          "propertyName": "id",
          "title": "ID",
          "isHidden": "true"
        }, {
          "propertyName": "pylon.name",
          "title": "Tower No."
        }, {
          "propertyName": "agreements.name",
          "title": "Agreements",
          'component': 'util/models-table/agreement',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "works.name",
          "title": "Works",
          'component': 'util/models-table/work',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "designers.firstObject.name",
          "title": "Designer",
          "isHidden": "true"
        }, {
          "propertyName": "designed_on",
          "title": "Designed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "checkers.firstObject.name",
          "title": "Checker",
          "isHidden": "true"
        }, {
          "propertyName": "checked_on",
          "title": "Checked On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "installers.firstObject.name",
          "title": "Installer",
          "isHidden": "true"
        }, {
          "propertyName": "installed_on",
          "title": "Installed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "description",
          "title": "Description",
          "isHidden": "true"
        }];
      }

      if (this.modelName === 'foundation') {
        this.columns = [{
          component: this.theme.expandToggleComponent,
          mayBeHidden: false
        }, {
          "propertyName": "id",
          "title": "ID",
          "isHidden": "true",
          "filteredBy": "filter[id][eq]",
          "filterPlaceholder": "Exact Value for this field"
        }, {
          "propertyName": "pylon.name",
          "title": "Tower No."
        }, {
          "propertyName": "agreements.name",
          "title": "Agreements",
          'component': 'util/models-table/agreement',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "works.name",
          "title": "Works",
          'component': 'util/models-table/work',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "designers.firstObject.name",
          "title": "Designer",
          "isHidden": "true"
        }, {
          "propertyName": "designed_on",
          "title": "Designed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "checkers.firstObject.name",
          "title": "Checker",
          "isHidden": "true"
        }, {
          "propertyName": "checked_on",
          "title": "Checked On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "constructors.firstObject.name",
          "title": "Constructor",
          "isHidden": "true"
        }, {
          "propertyName": "constructed_on",
          "title": "Constructed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "description",
          "title": "Description",
          "isHidden": "true"
        }];
      }

      if (this.modelName === 'slope') {
        this.columns = [{
          component: this.theme.expandToggleComponent,
          mayBeHidden: false
        }, {
          "propertyName": "id",
          "title": "ID",
          "isHidden": "true"
        }, {
          "propertyName": "pylon.name",
          "title": "Tower No."
        }, {
          "propertyName": "agreements.name",
          "title": "Agreements",
          'component': 'util/models-table/agreement',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "works.name",
          "title": "Works",
          'component': 'util/models-table/work',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "work_types.name",
          "title": "Work Types",
          "isHidden": "true",
          'component': 'util/models-table/work-type',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "surface_covers.name",
          "title": "Surface Covers",
          "isHidden": "true",
          'component': 'util/models-table/surface-cover',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "feature_types.name",
          "title": "Feature Type",
          "isHidden": "true",
          'component': 'util/models-table/feature-type',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "designers.firstObject.name",
          "title": "Designer",
          "isHidden": "true"
        }, {
          "propertyName": "designed_on",
          "title": "Designed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "checkers.firstObject.name",
          "title": "Checker",
          "isHidden": "true"
        }, {
          "propertyName": "checked_on",
          "title": "Checked On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "constructors.firstObject.name",
          "title": "Constructor",
          "isHidden": "true"
        }, {
          "propertyName": "constructed_on",
          "title": "Constructed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "description",
          "title": "Description",
          "isHidden": "true"
        }];
      }

      if (this.modelName === 'ground-investigation') {
        this.columns = [{
          component: this.theme.expandToggleComponent,
          mayBeHidden: false
        }, {
          "propertyName": "id",
          "title": "ID",
          "isHidden": "true"
        }, {
          "propertyName": "pylon.name",
          "title": "Tower No."
        }, {
          "propertyName": "agreements.name",
          "title": "Agreements",
          'component': 'util/models-table/agreement',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "carriers.firstObject.name",
          "title": "Carried out by",
          "isHidden": "true"
        }, {
          "propertyName": "carried_out_on",
          "title": "Completion of GI Works",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "designers.firstObject.name",
          "title": "Designer",
          "isHidden": "true"
        }, {
          "propertyName": "designed_on",
          "title": "Designed On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "description",
          "title": "Description",
          "isHidden": "true"
        }];
      }

      if (this.modelName === 'maintenance') {
        this.columns = [{
          component: this.theme.expandToggleComponent,
          mayBeHidden: false
        }, {
          "propertyName": "id",
          "title": "ID",
          "isHidden": "true"
        }, {
          "propertyName": "pylon.name",
          "title": "Tower No."
        }, {
          "propertyName": "agreements.name",
          "title": "Agreements",
          'component': 'util/models-table/agreement',
          'disableFiltering': true,
          'disableSorting': true
        }, {
          "propertyName": "type.name",
          "title": "Maintenance Type"
        }, {
          "propertyName": "inspectors.firstObject.name",
          "title": "Inspector",
          "isHidden": "true"
        }, {
          "propertyName": "inspected_on",
          "title": "Inspected On",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "last_inspected_on",
          "title": "Last Inspected On",
          "isHidden": "true",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "next_inspected_on",
          "title": "Next Inspected On",
          "isHidden": "true",
          'component': 'util/models-table/date'
        }, {
          "propertyName": "consequence_to_life",
          "title": "CTL",
          "isHidden": "true"
        }, {
          "propertyName": "frequency",
          "title": "Inspection Freq (year)",
          "isHidden": "true"
        }, {
          "propertyName": "description",
          "title": "Description",
          "isHidden": "true"
        }];
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "result", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modelName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setResult", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setResult"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setModelName", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setModelName"), _class.prototype)), _class));
  _exports.default = PylonDataViewer2Controller;
});