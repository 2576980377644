define("ember-quickstart/transforms/date-string", ["exports", "moment", "@ember-data/serializer/transform"], function (_exports, _moment, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateStringTransform extends _transform.default {
    deserialize(serialized) {
      let type = typeof serialized;

      if (type === 'string') {
        let offset = serialized.indexOf('+');

        if (offset !== -1 && serialized.length - 5 === offset) {
          offset += 3;
          return new Date(serialized.slice(0, offset) + ':' + serialized.slice(offset));
        }

        return new Date(serialized);
      } else if (type === 'number') {
        return new Date(serialized);
      } else if (serialized === null || serialized === undefined) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    }

    serialize(deserialized) {
      if (deserialized instanceof Date && !isNaN(deserialized)) {
        return (0, _moment.default)(deserialized).format('YYYY-MM-DD');
      } else {
        return null;
      }
    }

  }

  _exports.default = DateStringTransform;
});