define("ember-quickstart/routes/pylon-data-entry/slope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataEntrySlopeRoute extends Ember.Route {}

  _exports.default = PylonDataEntrySlopeRoute;
});