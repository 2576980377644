define("ember-quickstart/templates/pylon-data-viewer/foundation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AU/UmY1Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"content/pylon-data-viewer/foundation-result\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/pylon-data-viewer/foundation.hbs"
    }
  });

  _exports.default = _default;
});