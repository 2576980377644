define("ember-quickstart/routes/pylon-data-viewer/tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataViewerTowerRoute extends Ember.Route {}

  _exports.default = PylonDataViewerTowerRoute;
});