define("ember-quickstart/components/navbar/nav-item/push-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
  </li>
  */
  {
    id: "stBG0rzM",
    block: "{\"symbols\":[],\"statements\":[[9,\"li\",true],[12,\"class\",\"nav-item\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"a\",true],[12,\"class\",\"nav-link\",null],[12,\"data-widget\",\"pushmenu\",null],[12,\"href\",\"#\",null],[12,\"role\",\"button\",null],[10],[9,\"i\",true],[12,\"class\",\"fas fa-bars\",null],[10],[11],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "ember-quickstart/components/navbar/nav-item/push-menu.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});