define("ember-quickstart/routes/pylon-data-entry/tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataEntryTowerRoute extends Ember.Route {}

  _exports.default = PylonDataEntryTowerRoute;
});