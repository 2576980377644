define("ember-quickstart/templates/pylon-data-viewer2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NaH74ySV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"content/pylon-data-viewer2/filter-box\",[],[[\"@setResult\",\"@setModelName\"],[[27,[24,0],[\"setResult\"]],[27,[24,0],[\"setModelName\"]]]],null],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-4\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"content/pylon-data-viewer2/map-box\",[],[[\"@modelName\",\"@result\"],[[27,[24,0],[\"modelName\"]],[27,[24,0],[\"result\"]]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-8\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"content/pylon-data-viewer2/table-box\",[],[[\"@modelName\",\"@result\",\"@columns\"],[[27,[24,0],[\"modelName\"]],[27,[24,0],[\"result\"]],[27,[24,0],[\"columns\"]]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/pylon-data-viewer2.hbs"
    }
  });

  _exports.default = _default;
});