define("ember-quickstart/templates/pylon-data-verifier/access-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4terQBpY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"content/pylon-data-verifier/access-path\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/pylon-data-verifier/access-path.hbs"
    }
  });

  _exports.default = _default;
});