define("ember-quickstart/controllers/pylon-data-viewer", ["exports", "ember-concurrency", "proj4"], function (_exports, _emberConcurrency, _proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PylonDataViewerController = (_dec = Ember.inject.service, _dec2 = Ember.inject.controller, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class PylonDataViewerController extends Ember.Controller {
    //for refreshing page when the search button is clicked when basic information tab is chosen
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "pylonDataEntry", _descriptor2, this);

      _initializerDefineProperty(this, "pageSize", _descriptor3, this);

      _initializerDefineProperty(this, "point", _descriptor4, this);

      _initializerDefineProperty(this, "points", _descriptor5, this);

      _initializerDefineProperty(this, "pointSwap", _descriptor6, this);

      _initializerDefineProperty(this, "status", _descriptor7, this);

      _initializerDefineProperty(this, "mapLoaded", _descriptor8, this);

      _initializerDefineProperty(this, "circuitsGeoJson", _descriptor9, this);

      _initializerDefineProperty(this, "pop", _descriptor10, this);

      _initializerDefineProperty(this, "masterPop", _descriptor11, this);

      this.loadGeoJson();
    }

    async loadGeoJson() {
      this.circuitsGeoJson = await fetch('/geoJson/circuits.json').then(result => result.json()); //console.log(this.circuitsGeoJson)
      //console.log(this.circuitsGeoJson.features)
    }

    async loadCoordinates() {
      if (this.status === "BIpage1") this.status = "BIpage2";else this.status = "BIpage1";
      var firstProjection = 'PROJCS["Hong Kong 1980 Grid System",GEOGCS["Hong Kong 1980",DATUM["Hong_Kong_1980",SPHEROID["International 1924",6378388,297,AUTHORITY["EPSG","7022"]],TOWGS84[-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425],AUTHORITY["EPSG","6611"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4611"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",22.31213333333334],PARAMETER["central_meridian",114.1785555555556],PARAMETER["scale_factor",1],PARAMETER["false_easting",836694.05],PARAMETER["false_northing",819069.8],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","2326"]]';
      var secondProjection = "+proj=longlat +datum=WGS84 +no_defs";
      this.points = []; // console.log(this.selectedCircuit.map(x=>x.alias))

      this.mapLoaded = false;
      var hasMore = false;
      var pageSize = 750;
      var offset = -pageSize;

      do {
        offset = offset + pageSize; // console.log(moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ'))

        await this.store.query('pylon', {
          filter: {
            or: {
              // "is_published"                : {eq:true},
              "name": {
                in: Ember.isEmpty(this.selectedPylon) ? undefined : this.selectedPylon.map(x => x.name).toString()
              },
              "circuit.alias": {
                in: Ember.isEmpty(this.selectedCircuit) ? undefined : this.selectedCircuit.map(x => x.alias).toString()
              },
              "towers.constructed-on": {
                gte: Ember.isEmpty(this.startDate) ? undefined : moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')
              },
              "towers.checked-on": {
                gte: Ember.isEmpty(this.startDate) ? undefined : moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')
              },
              "towers.designed-on": {
                gte: Ember.isEmpty(this.startDate) ? undefined : moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')
              } // "conductors.designed-on"                : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "conductors.checked-on"                 : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "conductors.installed-on"               : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "foundations.constructed-on"            : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "foundations.checked-on"                : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "foundations.designed-on"               : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "slopes.constructed-on"                 : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "slopes.checked-on"                     : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "slopes.designed-on"                    : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "ground-investigations.designed-on"     : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "ground-investigations.carried-out-on"  : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "maintenances.inspected-on"             : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "maintenances.last-inspected-on"        : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},
              // "maintenances.next-inspected-on"        : {gte:isEmpty(this.startDate)? undefined:moment(this.startDate).utcOffset(-8).format('YYYY-MM-DDTHH:mm:ssZ')},

            }
          },
          fields: {
            "pylons": "name,centre-easting,centre-northing,circuit",
            "circuits": "alias"
          },
          page: {
            offset: offset,
            limit: pageSize
          },
          include: 'circuit'
        }).then(models => {
          models.toArray().forEach(model => {
            this.point = (0, _proj.default)(firstProjection, secondProjection, [model.centre_easting + 0, model.centre_northing + 0]);
            this.pointSwap = [this.point[1], this.point[0]]; // this.points.push(this.pointSwap)

            if (model.proj4Coordinates === undefined) {
              model.proj4Coordinates = this.pointSwap;
              model.pop = false; // console.log(this.points)
            }

            this.points.push(model);
          });
          hasMore = !Ember.isEmpty(models.links.next);
        });
      } while (hasMore === true);

      this.mapLoaded = true;
    } // @action search(){
    //     if (this.status==="BIpage1")
    //         this.status = "BIpage2"
    //     else
    //         this.status = "BIpage1"
    //     console.log(this.selectedAgreement, this.selectedCircuit, this.selectedPylon)
    // }


    popping(pop) {
      if (!this.masterPop) {
        // console.log('over')
        Ember.set(pop, 'pop', true);
        this.masterPop = true;
      }
    }

    unpop(pop) {
      if (this.masterPop) {
        // console.log('out')
        Ember.run.later(this, function () {
          // code here will execute within a RunLoop in about 500ms with this == myContext
          Ember.set(pop, 'pop', false);
          this.masterPop = false;
        }, 0.0001);
      }
    }

    resize(e) {
      e.target._onResize();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pylonDataEntry", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pageSize", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "point", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "points", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pointSwap", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'BIpage1';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "mapLoaded", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "circuitsGeoJson", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadCoordinates", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "loadCoordinates"), _class.prototype), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pop", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "masterPop", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "popping", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "popping"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unpop", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "unpop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resize", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "resize"), _class.prototype)), _class));
  _exports.default = PylonDataViewerController;
});