define("ember-quickstart/routes/pylon-data-viewer2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataViewer2Route extends Ember.Route {}

  _exports.default = PylonDataViewer2Route;
});