define("ember-quickstart/routes/pylon-data-viewer/foundation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataViewerFoundationRoute extends Ember.Route {}

  _exports.default = PylonDataViewerFoundationRoute;
});