define("ember-quickstart/instance-initializers/emt-inject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.inject('component:models-table-server-paginated', 'themeInstance', `theme:plain-html`);
    appInstance.inject('component:models-table', 'themeInstance', `theme:plain-html`);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});