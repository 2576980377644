define("ember-quickstart/transforms/numeric-string", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isNumber(value) {
    return value === value && value !== Infinity && value !== -Infinity;
  }

  class NumericStringTransform extends _transform.default {
    deserialize(serialized) {
      let transformed;

      if (serialized === '' || serialized === null || serialized === undefined) {
        return null;
      } else {
        transformed = Number(serialized);
        return isNumber(transformed) ? transformed : null;
      }
    }

    serialize(deserialized) {
      let transformed;

      if (deserialized === '' || deserialized === null || deserialized === undefined) {
        return null;
      } else {
        transformed = deserialized.toString();
        return isNumber(transformed) ? transformed : null;
      }
    }

  }

  _exports.default = NumericStringTransform;
});