define("ember-quickstart/routes/pylon-data-viewer/ground-investigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataViewerGroundInvestigationRoute extends Ember.Route {}

  _exports.default = PylonDataViewerGroundInvestigationRoute;
});