define("ember-quickstart/routes/pylon-data-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataEntryRoute extends Ember.Route {
    beforeModel() {
      this.transitionTo('pylon-data-entry.basic-information');
    }

  }

  _exports.default = PylonDataEntryRoute;
});