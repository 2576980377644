define("ember-quickstart/components/util/modal-form/default", ["exports", "@glimmer/component", "sweetalert2"], function (_exports, _component, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="modal-header">
      <h4 class="modal-title">Create "{{@focusedModel}}"</h4>
  </div>
  
  <div class="modal-body">
      <label>Name*</label>
      <Input type="text" @value={{this.input}} class="form-control" />
  </div>
  
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" {{action "onCreate" @focusedModel}}>Create</button>
  </div>
  */
  {
    id: "NJyWZTEk",
    block: "{\"symbols\":[\"@focusedModel\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"modal-header\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"h4\",true],[12,\"class\",\"modal-title\",null],[10],[1,1,0,0,\"Create \\\"\"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\\"\"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"modal-body\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"label\",true],[10],[1,1,0,0,\"Name*\"],[11],[1,1,0,0,\"\\n    \"],[7,\"input\",[[23,\"class\",\"form-control\",null],[23,\"type\",\"text\",null]],[[\"@value\"],[[27,[24,0],[\"input\"]]]],null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"modal-footer\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"button\",false],[23,\"class\",\"btn btn-primary\",null],[23,\"type\",\"button\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"onCreate\",[27,[24,1],[]]],null],[10],[1,1,0,0,\"Create\"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    meta: {
      moduleName: "ember-quickstart/components/util/modal-form/default.hbs"
    }
  });

  let UtilModalFormDefaultComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.controller, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class UtilModalFormDefaultComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "pylonDataEntry", _descriptor2, this);

      _initializerDefineProperty(this, "input", _descriptor3, this);
    }

    clearInput() {
      this.input = '';
    }

    async onCreate(modelName) {
      let hasError = false;

      try {
        await this.store.createRecord(modelName, {
          "name": this.input
        }).save();
      } catch (error) {
        hasError = true;
        let combinedMsg = '';
        error.errors.forEach(element => {
          let msg = "Error at \"" + element.source.pointer.split("/").pop() + "\"";

          if (Ember.isEmpty(element.detail)) {
            combinedMsg = combinedMsg + msg + "\r\n\r\n" + element.title;
          } else {
            combinedMsg = combinedMsg + msg + "\r\n\r\n" + element.title + "\r\n" + element.detail;
          }

          combinedMsg = combinedMsg + "\r\n------------------------------------\r\n";
        });
        alert(combinedMsg);
      } finally {
        if (hasError === false) {
          _sweetalert.default.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Created Successfully!',
            showConfirmButton: false,
            timer: 1500
          });

          this.clearInput();
          this.pylonDataEntry.toggleModal();
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pylonDataEntry", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "input", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clearInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clearInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCreate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onCreate"), _class.prototype)), _class));
  _exports.default = UtilModalFormDefaultComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UtilModalFormDefaultComponent);
});