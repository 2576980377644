define("ember-quickstart/components/main-sidebar/nav-item/pylon-data-verifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route="pylon-data-verifier">
  <li class="nav-item has-treeview">
      <a href="#" class="nav-link">
          <i class="nav-icon fas fa-user-check"></i>
          <p>Pylon Data Verifier</p>
      </a>
  </li>
  </LinkTo>
  */
  {
    id: "Tnz90FOQ",
    block: "{\"symbols\":[],\"statements\":[[7,\"link-to\",[],[[\"@route\"],[\"pylon-data-verifier\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[9,\"li\",true],[12,\"class\",\"nav-item has-treeview\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"a\",true],[12,\"href\",\"#\",null],[12,\"class\",\"nav-link\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"i\",true],[12,\"class\",\"nav-icon fas fa-user-check\",null],[10],[11],[1,1,0,0,\"\\n        \"],[9,\"p\",true],[10],[1,1,0,0,\"Pylon Data Verifier\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "ember-quickstart/components/main-sidebar/nav-item/pylon-data-verifier.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});