define("ember-quickstart/templates/pylon-data-entry/basic-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y0BadRG4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"content/pylon-data-entry/basic-information-form\",[],[[\"@confirmedPylon\",\"@loadMoreOptions\",\"@loadDefaultOptions\",\"@searchOptions\",\"@optionsPage\",\"@hasNext\",\"@options\",\"@focusedModel\",\"@status\"],[[27,[24,0],[\"pylonDataEntry\",\"confirmedPylon\"]],[27,[24,0],[\"pylonDataEntry\",\"loadMoreOptions\"]],[27,[24,0],[\"pylonDataEntry\",\"loadDefaultOptions\"]],[27,[24,0],[\"pylonDataEntry\",\"searchOptions\"]],[27,[24,0],[\"pylonDataEntry\",\"optionsPage\"]],[27,[24,0],[\"pylonDataEntry\",\"hasNext\"]],[27,[24,0],[\"pylonDataEntry\",\"options\"]],[27,[24,0],[\"pylonDataEntry\",\"focusedModel\"]],[27,[24,0],[\"pylonDataEntry\",\"status\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/pylon-data-entry/basic-information.hbs"
    }
  });

  _exports.default = _default;
});