define("ember-quickstart/routes/pylon-data-entry/basic-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PylonDataEntryBasicInformationRoute extends Ember.Route {}

  _exports.default = PylonDataEntryBasicInformationRoute;
});