define("ember-quickstart/adapters/application", ["exports", "@ember-data/adapter/json-api", "ember-quickstart/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _jsonApi, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ApplicationAdapter = (_dec = Ember.computed('session.data.authenticated.access_token'), (_class = (_temp = class ApplicationAdapter extends _jsonApi.default.extend(_dataAdapterMixin.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", `${_environment.default.API.host}`);

      _defineProperty(this, "namespace", `${_environment.default.API.namespace}`);
    }

    get headers() {
      let headers = {};

      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`; // OAuth 2
      }

      return headers;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = ApplicationAdapter;
});