define("ember-quickstart/components/util/models-table/work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul style='margin: 0; padding: 0;'>
    {{#each @record.works as |work|}}
          <li style='list-style-type: none;'>-{{work.name}}</li>
    {{/each}}
  </ul>
  */
  {
    id: "PvcF6Stq",
    block: "{\"symbols\":[\"work\",\"@record\"],\"statements\":[[9,\"ul\",true],[12,\"style\",\"margin: 0; padding: 0;\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,2],[\"works\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"li\",true],[12,\"style\",\"list-style-type: none;\",null],[10],[1,1,0,0,\"-\"],[1,0,0,0,[27,[24,1],[\"name\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[11]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    meta: {
      moduleName: "ember-quickstart/components/util/models-table/work.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});